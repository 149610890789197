import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Login from '../components/login/Login';

const login = () => {
  return (
    <div className="page-login">
      <Login />
    </div>
  );
};

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['index']))
  }
});

export default login;
