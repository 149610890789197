import {
  loginApiParams,
  loginHeaders,
  resetPasswordApiParams,
  forgotPasswordApiParams
} from './loginApiParams';
import {
  login as loginEndpoint,
  resetPassword as resetPasswordEndpoint,
  forgotPassword as forgotPasswordEndpoint,
  host
} from '../../shared/api/endpoints';
import { objectHasKey } from '../../../utils/functions/validator/objectValidator';
import errorNotification from '../../shared/notifications/errorNotification';

export const login = async body => {
  try {
    const response = await fetch(host + loginEndpoint, {
      ...loginApiParams,
      body: JSON.stringify(body),
      headers: loginHeaders
    });
    switch (true) {
      case response.status >= 400 && response.status < 600: {
        const responseJson = await response.json();
        const errorMessage = objectHasKey(responseJson, 'message')
          ? responseJson.message
          : 'Ha ocurrido un error';
        errorNotification(errorMessage);
        throw Error();
      }
      default:
        return response.json();
    }
  } catch (error) {
    throw Error();
  }
};

export const forgotPassword = async body => {
  try {
    const response = await fetch(host + forgotPasswordEndpoint, {
      ...forgotPasswordApiParams,
      body: JSON.stringify(body),
      headers: loginHeaders
    });
    switch (true) {
      case response.status >= 400 && response.status < 600: {
        const responseJson = await response.json();
        const errorMessage = objectHasKey(responseJson, 'message')
          ? responseJson.message
          : 'Ha ocurrido un error';
        errorNotification(errorMessage);
        throw Error();
      }
      default:
        return response.json();
    }
  } catch (error) {
    throw Error();
  }
};

export const resetPassword = async body => {
  try {
    const response = await fetch(host + resetPasswordEndpoint, {
      ...resetPasswordApiParams,
      body: JSON.stringify(body),
      headers: loginHeaders
    });
    switch (true) {
      case response.status >= 400 && response.status < 600: {
        const responseJson = await response.json();
        const errorMessage = objectHasKey(responseJson, 'message')
          ? responseJson.message
          : 'Ha ocurrido un error';
        errorNotification(errorMessage);
        throw Error();
      }
      default:
        return response.json();
    }
  } catch (error) {
    throw Error();
  }
};
