const CompanyDisplay = () => {
  return (
    <div className="login-company-display">
      <div className="top-asset">
        <img src="/form-asset-1.svg" alt="DeepUpSell asset" />
      </div>
      <div className="company-logo">
        <img src="/logo.svg" alt="DeepUpSell logo" />
      </div>
      <div className="bottom-asset">
        <img src="/form-asset-2.svg" alt="DeepUpSell asset" />
      </div>
    </div>
  );
};

export default CompanyDisplay;
