import Card from '../shared/card/Card';
import Head from '../shared/head/Head';
import CompanyDisplay from './components/CompanyDisplay';
import LoginForm from './components/LoginForm';

const Login = () => {
  return (
    <div className="login-wrapper">
      <Head title="Login | DeepUpSell" />
      <div className="login-form-wrapper">
        <Card
          className="login-form-wrapper-card"
          bodyStyle={{ padding: '24px' }}
        >
          <LoginForm />
        </Card>
      </div>
      <div className="login-company-display-wrapper">
        <CompanyDisplay />
      </div>
    </div>
  );
};

export default Login;
