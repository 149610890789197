import { useRouter } from 'next/dist/client/router';
import { useEffect, useMemo, useState } from 'react';
import useTranslation from '../../../i18n/hooks/useTranslation';
import { ArrowLeft } from '../../shared/assets/icons';
import Button from '../../shared/button/Button';
import { Form, FormItem, useForm } from '../../shared/form/Form';
import Input from '../../shared/inputs/input/Input';
import successNotification from '../../shared/notifications/successNotification';
import Title from '../../shared/typo/title/Title';
import { login, forgotPassword, resetPassword } from '../api/loginApi';

const LoginForm = () => {
  const router = useRouter();
  const { translation } = useTranslation();

  const [loginMode, setLoginMode] = useState('default');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const checkIfModeResetPassword = () => {
      const searchParams = new URLSearchParams(router.query);
      const action = searchParams.get('action');
      if (action === 'rp') {
        setLoginMode('resetPassword');
      }
    };
    checkIfModeResetPassword();
    return () => {
      setLoginMode('default');
    };
  }, [router]);

  const [form] = useForm();

  const btnText = useMemo(() => {
    switch (loginMode) {
      case 'forgotPassword':
      case 'resetPassword':
        return translation('general.send');
      case 'default':
      default:
        return translation('login.loginAction');
    }
  }, [loginMode, translation]);

  const loginSuccess = () => {
    router.push('/');
    form.resetFields();
    setIsLoading(false);
  };

  const resetFormSuccess = () => {
    form.resetFields();
    setLoginMode('default');
    setIsLoading(false);
  };
  const forgotPasswordSuccess = () => {
    successNotification(translation('login.successForgotPassword'));
    resetFormSuccess();
  };
  const resetPasswordSuccess = () => {
    successNotification(translation('login.successResetPassword'));
    resetFormSuccess();
  };

  const loginError = () => {
    setIsLoading(false);
  };

  const forgotPasswordError = () => {
    setIsLoading(false);
  };

  const obtainUrlToken = () => {
    try {
      return router.asPath.split('&')[1].split('=')[1];
    } catch (error) {
      return '';
    }
  };

  const onFinish = values => {
    setIsLoading(true);
    switch (loginMode) {
      case 'forgotPassword':
        forgotPassword(values)
          .then(forgotPasswordSuccess)
          .catch(forgotPasswordError);
        break;
      case 'resetPassword': {
        const token = obtainUrlToken();
        resetPassword({ ...values, token })
          .then(resetPasswordSuccess)
          .catch(forgotPasswordError);
        break;
      }
      case 'default':
      default:
        login(values).then(loginSuccess).catch(loginError);
    }
  };

  const loginTitle = useMemo(() => {
    switch (loginMode) {
      case 'forgotPassword':
        return translation('login.title.loginFormForgottenPassword');
      case 'resetPassword':
        return translation('login.title.loginFormResetPassword');
      default:
        return translation('login.title.loginForm');
    }
  }, [loginMode, translation]);

  return (
    <div className="login-form">
      <Title level={4}>{loginTitle}</Title>
      <Form form={form} onFinish={onFinish}>
        <FormItem
          hidden={loginMode === 'resetPassword'}
          rules={[
            {
              required: loginMode !== 'resetPassword',
              type: 'email',
              message: translation('login.errors.loginEmailError')
            }
          ]}
          name="email"
        >
          <Input editModeFixed label={translation('general.email')} autoFocus />
        </FormItem>
        <FormItem
          name="password"
          hidden={loginMode === 'forgotPassword'}
          className="password-wrapper"
          rules={[
            {
              required: loginMode !== 'forgotPassword',
              message: translation('login.errors.loginPasswordError'),
              min: 6,
              max: 255
            }
          ]}
        >
          <Input
            editModeFixed
            type="password"
            label={translation('general.password')}
          />
        </FormItem>
        <FormItem
          hidden={loginMode !== 'resetPassword'}
          name="repeatedPassword"
          rules={[
            ({ getFieldValue }) => ({
              validator(___, value) {
                if (loginMode !== 'resetPassword') {
                  return Promise.resolve();
                }
                const newPassword = getFieldValue('password');

                return newPassword === value
                  ? Promise.resolve()
                  : Promise.reject(
                      translation(
                        'account.changePassword.errors.confirmNewPasswordError'
                      )
                    );
              }
            })
          ]}
        >
          <Input
            label={translation('account.changePassword.confirmNewPassword')}
            type="password"
            editModeFixed
            autoFocus={false}
          />
        </FormItem>
        <FormItem hidden={loginMode !== 'default'}>
          <div
            onClick={() => setLoginMode('forgotPassword')}
            role="presentation"
            className="password-recovery-wrapper"
          >
            {translation('login.passwordRecovery')}
          </div>
        </FormItem>
        <div className="loginBtn-wrapper">
          <Button
            htmlType="submit"
            size="large"
            type="primary"
            className="capitalize"
            isLoading={isLoading}
          >
            {btnText}
          </Button>
        </div>
        {loginMode === 'forgotPassword' && (
          <div
            onClick={resetFormSuccess}
            role="presentation"
            className="go-back first-letter"
          >
            <ArrowLeft />
            <span>{translation('general.goBack')}</span>
          </div>
        )}
      </Form>
    </div>
  );
};

export default LoginForm;
